import React, { useLayoutEffect } from "react"
import Layout from "../components/layout"
import RoutesSection from "../components/RoutesSection"
import { useTranslation, Trans } from "react-i18next"
import HomeBanner from "../components/home/HomeBanner";
import HomeSubBanner from "../components/home/HomeSubBanner";
import HomeGallery from "../components/home/HomeGallery";

const IndexPage = (props) => {
  const { t, i18n } = useTranslation();
  if (i18n.language !== "pl") i18n.changeLanguage("pl")
  useLayoutEffect(() => { 
    
  });
  return (
    <Layout props={props}>
      <HomeBanner />
      <HomeSubBanner />
      <HomeGallery />
      <RoutesSection includeHeader="true"></RoutesSection>
    </Layout>
  )
}

export default IndexPage;
